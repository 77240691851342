import { HeadProps, Link, navigate } from "gatsby";
import React from "react";
import toast from "react-hot-toast";
import { handleErrorResponse } from "../../app/apiSlice";
import { useRequireAuthentication } from "../../features/user/hooks";
import {
  useCurrentUserQuery,
  useSessionTokenQuery,
  useUpdateUserMutation,
} from "../../features/user/userApiSlice";
import Layout from "../../components/Layout";
import FormPageWrapper from "../../components/FormPageWrapper";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonSpinner from "../../components/ButtonSpinner";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../../components/FormErrorMessage";
import Seo from "../../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface ChangeEmailFormValues {
  mail: string;
  pass: string;
}

const ChangeEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeEmailFormValues>();

  useRequireAuthentication();

  const { data: currentUser } = useCurrentUserQuery();
  const { data: sessionToken } = useSessionTokenQuery(undefined, {
    skip: !currentUser?.is_authenticated,
  });
  const [updateUserTrigger, { isLoading }] = useUpdateUserMutation();

  const onSubmit: SubmitHandler<ChangeEmailFormValues> = (values) => {
    console.log(values);
    updateUserTrigger({
      sessionToken,
      id: currentUser.data.uuid,
      payload: {
        attributes: {
          mail: values.mail,
          pass: { existing: values.pass },
        },
      },
    })
      .unwrap()
      .then((success) => {
        toast.success("Email updated successfully.");
        navigate("/user/settings");
      })
      .catch((error) => {
        console.error(error);
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "ChangeEmail: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  return (
    <Layout>
      <FormPageWrapper title="Change email">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              htmlFor="mail"
              className="font-bold text-neutral-700 block mb-1 mt-2"
            >
              New email
            </label>
            <input
              id="mail"
              {...register("mail", { required: true, maxLength: 254 })}
              placeholder={currentUser?.data?.mail}
              type="email"
              className={`border shadow p-3 w-full rounded text-neutral-700 ${
                errors.mail ? "border-red-300" : "border-neutral-300"
              }`}
            />
            {errors.mail?.type === "required" && (
              <FormErrorMessage>
                The email address is required.
              </FormErrorMessage>
            )}
            {errors.mail?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 254 characters.
              </FormErrorMessage>
            )}
            <label
              htmlFor="pass"
              className="font-bold text-neutral-700 block mb-1 mt-2"
            >
              Confirm your password
            </label>
            <input
              id="pass"
              {...register("pass", {
                required: true,
                maxLength: 512,
                minLength: 6,
              })}
              placeholder="xxxxxxxx"
              type="password"
              className={`border shadow p-3 w-full rounded text-neutral-700 ${
                errors.pass ? "border-red-300" : "border-neutral-300"
              }`}
            />
            {errors.pass?.type === "required" && (
              <FormErrorMessage>The new password is required.</FormErrorMessage>
            )}
            {errors.pass?.type === "maxLength" && (
              <FormErrorMessage>
                Can not exceed 512 characters.
              </FormErrorMessage>
            )}
            {errors.pass?.type === "minLength" && (
              <FormErrorMessage>
                Must be at least 6 characters.
              </FormErrorMessage>
            )}
          </div>
          <ButtonPrimary
            type="submit"
            size="20"
            disabled={!currentUser?.data?.uuid || !sessionToken || isLoading}
          >
            Submit
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
          <Link
            to="/user/settings"
            className="text-red-700 underline hover:text-red-600 inline-block ml-8"
          >
            Cancel
          </Link>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default ChangeEmail;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Change email | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
